import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { SpinnerDialog } from '@awesome-cordova-plugins/spinner-dialog/ngx';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AddDietaPageModule } from './modals/add-dieta/add-dieta.module';
import { AddMomentoDiaPageModule } from './modals/add-momento-dia/add-momento-dia.module';
import { ApiService } from './services/api.service';
import { CommunFuntionsService } from './services/commun-funtions.service';
import { DialogosService } from './services/dialogos.service';
import { AuthGuard } from './shared/guards/auth.guard';

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    AddDietaPageModule,
    AddMomentoDiaPageModule,
    BrowserModule, IonicModule.forRoot(), AppRoutingModule],
  providers: [
    CommunFuntionsService,
    SpinnerDialog,
    AuthGuard,
    ApiService,
    DialogosService,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }],
  bootstrap: [AppComponent],
})
export class AppModule {}
