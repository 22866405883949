import { Injectable } from '@angular/core';
import axios from 'axios';

@Injectable({
  providedIn: 'root'
})

export class ApiService {

  // SERVER 
  domain_server: string = "https://martina-app-dietas-backend.sisboa.net/Martina-App-Dietas-Backend/public";
  //END-POINT
  login_link: string = "/api/auth/Users/login";
  getMe_link: string = "/api/auth/me";

  getDietas_link: string = "/api/auth/get-all-dietas";
  getDias_link: string = "/api/auth/get-all-dias";
  getMomentosDelDia_link: string = "/api/auth/get-all-momentos";

  createDietas_link: string = "/api/auth/create-dieta";
  createMomentoDelDia_link: string = "/api/auth/create-momento";

  updateDietas_link: string = "/api/auth/update-dieta";
  updateMomentoDelDia_link: string = "/api/auth/update-momento";


  deleteDietas_link: string = "/api/auth/delete-dieta";
  deleteMomentoDelDia_link: string = "/api/auth/delete-momento";

  constructor() { }

  getToken(token: string) {
    let config = {
      headers: {
        "Content-type": "application/json",
        "Authorization": "Bearer " + token + ""
      },
    };
    return config;
  }


  // LOGIN AND GETTING TOKEN
  public LoginUser_Server = async (postData: any) => {
    return axios.post(`${this.domain_server}${this.login_link}`, postData).then(({ data }) => data);
  };

  public GetMe_Server = async (postData: any) => {
    return axios.post(`${this.domain_server}${this.getMe_link}`, postData).then(({ data }) => data);
  };




  // GET
  public GetAllDietas_Server = async (postData: any) => {
    return axios.post(`${this.domain_server}${this.getDietas_link}`, postData).then(({ data }) => data);
  };
  public GetAllDias_Server = async (postData: any) => {
    return axios.post(`${this.domain_server}${this.getDias_link}`, postData).then(({ data }) => data);
  };
  public GetAllMomentos_Server = async (postData: any) => {
    return axios.post(`${this.domain_server}${this.getMomentosDelDia_link}`, postData).then(({ data }) => data);
  };


  // CREATE
  public CreateDietas_Server = async (postData: any, token: any) => {
    let miToken = await this.getToken(token);
    return axios.post(`${this.domain_server}${this.createDietas_link}`, postData, miToken).then(({ data }) => data);
  };
  public CreateMomentoDelDia_Server = async (postData: any, token: any) => {
    let miToken = await this.getToken(token);
    return axios.post(`${this.domain_server}${this.createMomentoDelDia_link}`, postData, miToken).then(({ data }) => data);
  };


  // UPDATE
  public UpdateDietas_Server = async (postData: any, token: any) => {
    let miToken = await this.getToken(token);
    return axios.post(`${this.domain_server}${this.updateDietas_link}`, postData, miToken).then(({ data }) => data);
  };
  public UpdateMomentoDelDia_Server = async (postData: any, token: any) => {
    let miToken = await this.getToken(token);
    return axios.post(`${this.domain_server}${this.updateMomentoDelDia_link}`, postData, miToken).then(({ data }) => data);
  };


  // DELETE
  public DeleteDietas_Server = async (postData: any, token: string) => {
    let miToken = await this.getToken(token);
    return axios.post(`${this.domain_server}${this.deleteDietas_link}`, postData, miToken).then(({ data }) => data);
  };
  public DeleteMomentoDelDia_Server = async (postData: any, token: any) => {
    let miToken = await this.getToken(token);
    return axios.post(`${this.domain_server}${this.deleteMomentoDelDia_link}`, postData, miToken).then(({ data }) => data);
  };




}
