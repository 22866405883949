import { Injectable } from '@angular/core';
import * as moment from 'moment';


@Injectable({
  providedIn: 'root'
})
export class CommunFuntionsService {

  constructor() { }



  public getDiaActual() {
    let date = new Date();
    //let diaActual = arrayDias[(date.getDay()-1)].nombre      en letra
    return date.getDay();
  }
  public getDiaActualLetra(position:number, arrayDias:any) {
    let diaLetra = arrayDias[position].nombre;
    return diaLetra;
  }


  dias = ["Lunes", "Martes", "Miercoles", "Jueves", "Viernes", "Sabado", "Domingo"];
  meses = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];
  public diaSemana() {
    var fechaActual = moment();
    let date = new Date();
    let fechaNum = date.getUTCDate();
    let mes_name = date.getMonth();
    // alert(this.dias[date.getDay()] + " " + fechaNum + " de " + meses[mes_name] + " de " + date.getFullYear());
  }





  public TokenFormat(data: string) {
    let token = {
      token: data
    }
    return token;
  }



}
