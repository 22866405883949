import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ApiService } from 'src/app/services/api.service';
import { CommunFuntionsService } from 'src/app/services/commun-funtions.service';
import { DialogosService } from 'src/app/services/dialogos.service';
import { AuthGuard } from 'src/app/shared/guards/auth.guard';
import { I_DietaFormat } from '../../Interfaces/I_Dieta';
import { AddMomentoDiaPage } from '../add-momento-dia/add-momento-dia.page';

@Component({
  selector: 'app-add-dieta',
  templateUrl: './add-dieta.page.html',
  styleUrls: ['./add-dieta.page.scss'],
})
export class AddDietaPage implements OnInit {

  i_Dieta: I_DietaFormat;
  arrayDias: any[];
  arrayMomentosDelDia:any[];
  diaSelected: string;
  momentoSelected: string;

  constructor(
    public dialogos: DialogosService,
    public communFun: CommunFuntionsService,
    public api: ApiService,
    public modalControler: ModalController,
    public communFunc: CommunFuntionsService,
    public authGuard: AuthGuard
  ) {
  
    this.i_Dieta = {
      Alimentos: '',
      Calorias: '0',
      GramoProteina: '0',
      Gramos: '0',
      Momento: '',
      dia: '',
    }

    console.log(this.diaSelected);
    


  }

  ngOnInit() {
  }

  ionViewDidEnter() {
    this.GetAll_Dias();
    this.GetAll_MomentosDelDia();
    // this.diaSelected = this.communFun.getDiaActual().toString();

    // console.log(this.communFun.getDiaActual(this.arrayDias));
  }

  public CerrarModal() {
    this.modalControler.dismiss({});
  }

  public CerrarModalData() {
    this.modalControler.dismiss({ recarga: "true" });
  }


  public GetAll_Dias() {
    let arratDiaTemp: any[];
    let date = new Date();

    let token = this.communFunc.TokenFormat(this.authGuard.userDataLogueado.token);
    this.api.GetAllDias_Server(token).then(async data => {

      if (data.success) {
        this.arrayDias = data.payload;
        this.diaSelected  = await this.communFun.getDiaActual().toString();
      } else {
        this.dialogos.Msg_Error(data.msg, 2500);
      }

    })
  }



  public GetAll_MomentosDelDia() {
    let token = this.communFunc.TokenFormat(this.authGuard.userDataLogueado.token);
    this.api.GetAllMomentos_Server(token).then(data => {

      if (data.success) {
        this.arrayMomentosDelDia = data.payload;
        console.log(data.payload);
        
      }else{
        this.dialogos.Msg_Error(data.msg, 2500);
      }

    })
  }







  public ionChangeMethod(event: any, value: any) {
    switch (value) {
      case 'Alimentos':
        this.i_Dieta.Alimentos = event.detail.value;
        this.i_Dieta.Alimentos = this.i_Dieta.Alimentos.toUpperCase();
        break;
      case 'Calorias':
        this.i_Dieta.Calorias = event.detail.value;
        break;
      case 'GramoProteina':
        this.i_Dieta.GramoProteina = event.detail.value;
        break;
      case 'Gramos':
        this.i_Dieta.Gramos = event.detail.value; 
        break;
      case 'Momento':
        this.i_Dieta.Momento = this.momentoSelected;
        break;
      case 'dia':
        this.i_Dieta.dia =  this.communFun.getDiaActualLetra((parseInt(this.diaSelected) - 1), this.arrayDias);
        break;
      default:
        break;
    }
  }



  public async Registrar() {

    console.log(this.i_Dieta);
    
    if (this.i_Dieta.Alimentos.length < 1 || this.i_Dieta.Calorias.length < 1 ||
      this.i_Dieta.GramoProteina.length < 1 || this.i_Dieta.Gramos.length < 1 ||
      this.i_Dieta.Momento.length < 1 || this.i_Dieta.dia.length < 1) {

      return this.dialogos.Msg_Error('Faltan datos obligatorios', 3000);
    }

    let confirmation = await this.dialogos.Dialogo_PreguntarAccion('Finalizando', 'Deseas registrar los datos suministrados?')
    if (confirmation) {

      let token = await this.authGuard.userDataLogueado.token;
      this.api.CreateDietas_Server(this.i_Dieta, token).then(data => {
        if (data.success) {
          this.dialogos.Msg_Success(data.msg, 2000);
          this.CerrarModalData();
        } else { this.dialogos.Msg_Error(data.msg, 2000); }
      });

    }
  }

  public async ModalCrerNuevoMomento() {
    try {
      const modal = await this.modalControler.create({
        component: AddMomentoDiaPage,
        cssClass: 'my-custom-class',
        componentProps: {
          'codigo': ""
        },
      });
      await modal.present();
      try {
        const { data } = await modal.onDidDismiss()
        if (data) {
          this.GetAll_MomentosDelDia();
        } else { }
      } catch (error) { console.log('error al leer datos no existe!');  }

      
    } catch (error) { this.dialogos.Msg_Error('Ha ocurrido un problema!', 1800);     console.log(error);}
  }




}
