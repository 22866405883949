import { Injectable } from '@angular/core';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class DialogosService {

  constructor() { }



  // MENSAJES COMUNES
  Msg_Success(mensaje, tiempo) {
    //bien
    const Toast = Swal.mixin({
      toast: true,
      // position: 'top-end',
      showConfirmButton: false,
      timer: tiempo,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
      }
    })

    Toast.fire({
      icon: 'success',
      title: '' + mensaje
    })
    // fin bien
  }
  Msg_Error(mensaje, tiempo) {
    //error
    const Toast = Swal.mixin({
      toast: true,
      // position: 'top-end',
      showConfirmButton: false,
      timer: tiempo,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
      }
    })

    Toast.fire({
      icon: 'error',
      title: '' + mensaje
    })
    // fin error
  }
  Msg_Info(titleMsg, mensaje) {
    //error
    const Toast = Swal.mixin({
      toast: true,
      title: titleMsg,
      // position: 'top-end',
      showConfirmButton: true,
      confirmButtonText: 'OK',
      confirmButtonColor: '#0f8ea3',
      didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
      }
    })

    const lines = ['You are. \n FEO \n'];
    Toast.fire({
      icon: 'info',
      title: '' + titleMsg,
      html: mensaje,
      // text: 'MUNDO '+lines.join('\n\n')+' DE PIEDRA \n\n '  + mensaje
    })
    // fin error
  }
  Dialogo_PreguntarAccion = async (TITLE: string, TEXT: string) => {
    let confirmacion = false;
    const Toast = Swal.mixin({
      toast: true,
      // position: 'top-end',
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonText: 'Confirmar',
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
      },
    })
    await Toast.fire({
      icon: 'question',
      title: TITLE,
      text: TEXT
    }).then((result) => {
      if (result.isConfirmed) {
        confirmacion = true;

      } else {
        confirmacion = false;
      }
    })
    return confirmacion;
  }
}
