import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { I_Update_MomentoDelDia, I_Create_MomentoDelDia, I_Delete_MomentoDelDia } from 'src/app/Interfaces/I_MomentoDia';
import { ApiService } from 'src/app/services/api.service';
import { CommunFuntionsService } from 'src/app/services/commun-funtions.service';
import { DialogosService } from 'src/app/services/dialogos.service';
import { AuthGuard } from 'src/app/shared/guards/auth.guard';

@Component({
  selector: 'app-add-momento-dia',
  templateUrl: './add-momento-dia.page.html',
  styleUrls: ['./add-momento-dia.page.scss'],
})
export class AddMomentoDiaPage implements OnInit {
  i_create_momento: I_Create_MomentoDelDia;
  i_deleteMomento: I_Delete_MomentoDelDia;
  i_updateMomento: I_Update_MomentoDelDia;

  arrayMomentosDelDia: any[];
  momentoSelected: string;
  horarioSelected: string;

  crearEditar: boolean = false;
  textoCrearEditar: string = '';

  constructor(
    public dialogos: DialogosService,
    public communFun: CommunFuntionsService,
    public api: ApiService,
    public modal: ModalController,
    public communFunc: CommunFuntionsService,
    public authGuard: AuthGuard) {

    this.i_create_momento = {
      nombre: '',
      horario: '0',
    }

    this.i_deleteMomento = {
      id: ''
    }

    this.i_updateMomento = {
      id: '',
      nombre: '',
      horario: ''
    }

  }



  ngOnInit() {
  }

  ionViewDidEnter() {
    this.GetAll_MomentosDelDia();
    this.crearEditarFunc();
  }

  public crearEditarFunc() {
    if (this.crearEditar) {
      this.textoCrearEditar = 'EDITANDO MOMENTO';
    } else {
      this.textoCrearEditar = 'CREADNO MOMENTO';
    }
  }
  public CerrarModalData() {
    this.modal.dismiss({ recarga: "true" });
  }


  public GetAll_MomentosDelDia() {
    let token = this.communFunc.TokenFormat(this.authGuard.userDataLogueado.token);
    this.api.GetAllMomentos_Server(token).then(data => {

      if (data.success) {
        this.arrayMomentosDelDia = data.payload;
        console.log(data.payload);

      } else {
        this.dialogos.Msg_Error(data.msg, 2500);
      }

    })
  }


  public ionChangeMethod(event: any, value: any) {
    switch (value) {
      case 'Nombre':
        try {
          this.i_create_momento.nombre = this.i_create_momento.nombre.toUpperCase();
        } catch (error) { }
        break;

      case 'crearEditar':
        try { this.crearEditarFunc() } catch (error) { }
        break;

      default:
        break;
    }
  }


  public async Antes_De_Editar_Momento(id: string, nombre: string, horario: string) {

    this.i_create_momento.nombre = nombre;
    this.i_create_momento.horario = horario;
    this.i_updateMomento.id = id;
  }


  public async Delete_Momento(id: string) {
    let confirmation = await this.dialogos.Dialogo_PreguntarAccion('Eliminando Registro', 'Desea remover este registro ?');
    if (confirmation) {
      let token = this.authGuard.userDataLogueado.token;
      this.i_deleteMomento = { id };
      this.api.DeleteMomentoDelDia_Server(this.i_deleteMomento, token).then(data => {
        if (data.success) {
          this.dialogos.Msg_Success(data.msg, 2000);
          this.GetAll_MomentosDelDia();
        } else {
          this.dialogos.Msg_Error(data.msg, 2500);
        }
      })
    }



  }



  public async Registrar() {

    console.log(this.i_create_momento);

    if (this.i_create_momento.nombre.length < 1 || this.i_create_momento.horario.length < 1) {
      return this.dialogos.Msg_Error('Faltan datos obligatorios', 3000);
    }

    let confirmation = await this.dialogos.Dialogo_PreguntarAccion('Finalizando', 'Deseas registrar los datos suministrados?')
    if (confirmation) {

      let token = await this.authGuard.userDataLogueado.token;
      this.api.CreateMomentoDelDia_Server(this.i_create_momento, token).then(data => {
        if (data.success) {
          this.dialogos.Msg_Success(data.msg, 2000);
          this.GetAll_MomentosDelDia();
        } else { this.dialogos.Msg_Error(data.msg, 2000); }
      });

    }
  }



  public async Editar() {

    this.i_updateMomento.nombre = this.i_create_momento.nombre,
    this.i_updateMomento.horario = this.i_create_momento.horario;

    console.log(this.i_updateMomento);

    let confirmation = await this.dialogos.Dialogo_PreguntarAccion('Modificando Registro', 'Desea Editar este registro ?');
    if (confirmation) {
      let token = this.authGuard.userDataLogueado.token;
      this.api.UpdateMomentoDelDia_Server(this.i_updateMomento, token).then(data => {
        if (data.success) {
          this.dialogos.Msg_Success(data.msg, 2000);
          this.GetAll_MomentosDelDia();
        } else {
          this.dialogos.Msg_Error(data.msg, 2500);
        }
      })
    }


  }



}
