import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { AddMomentoDiaPageRoutingModule } from './add-momento-dia-routing.module';

import { AddMomentoDiaPage } from './add-momento-dia.page';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    AddMomentoDiaPageRoutingModule
  ],
  declarations: [AddMomentoDiaPage]
})
export class AddMomentoDiaPageModule {}
