import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {


  constructor(private router: Router){}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

     // VALIDATING USER LOGED
     if (this.userDataLogueado.logeado) {
      return true;
    } else {
      this.router.navigate(['/login']);
      return false;
    }
  }


  userDataLogueado = {
    active: 0,
    logeado: false,
    name: '',
    codigoUsuario: '',
    email: '',
    tipoUsuario: '',
    celular_number: '',
    token: '',
    rememberEmail: false
  }
 
  public async SetUserDataLogued(data: any, token: string, rememberEmail:boolean) {
    let validator =  await this.validate_Activo_Logueado(data);
    this.userDataLogueado = {
      active: validator.activo,
      logeado: validator.loqueado,
      name: data.name,
      codigoUsuario: data.codigoUsuario,
      email: data.email,
      tipoUsuario: data.tipoUsuario,
      celular_number: data.celular_number,
      token: token,
      rememberEmail: rememberEmail
    }
    this.Set_user_data_to_local_storage(this.userDataLogueado);
  }

  
  private validate_Activo_Logueado(data:any){
    let UserObject = {
      loqueado: false,
      activo: 0
    }
    switch (data.active) {
      case '1':
        UserObject.loqueado = true;
        UserObject.activo = 1;
        break;
      default:
        UserObject.loqueado = false;
        UserObject.activo = 0;
        break;
    }
    return UserObject;
  }



    
    // METODOS
    public Set_user_data_to_local_storage(arrayObject: any) {
      localStorage.setItem('USER_DATA_LOGUEADO', JSON.stringify(arrayObject));
    }
  
    public Get_cuser_data_from_local_storage() {
      let dataTemp: any;
      if (localStorage.getItem('USER_DATA_LOGUEADO')) {
        dataTemp = JSON.parse(localStorage.getItem('USER_DATA_LOGUEADO'));
      } else {
        dataTemp = "";
      }
      return dataTemp;
    }




  
}
